import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import ClientSlider from '../components/ClientSlider';
import { IMAGES, SVGICON } from '../constants/theme';
import LatestSlider from '../elements/LatestSlider';
import NewsLetter from '../elements/NewsLetter';
import PageTitle from '../elements/PageTitle';
import WorkoutSlider from '../elements/WorkoutSlider';

const  wrapperBlog = [
    {title:"Right Nutrition", image:IMAGES.boxlog1},
    {title:"Health & Fitness", image:IMAGES.boxlog2},
    {title:"Gym & Exercise", image:IMAGES.boxlog3},
    {title:"Health Motivation", image:IMAGES.boxlog4},
    {title:"Healthy Heart", image:IMAGES.boxlog5},
    {title:"Smoothie & Juice", image:IMAGES.boxlog6},
    {title:"Yoga & Exercise", image:IMAGES.boxlog7},
    {title:"Health Motivation", image:IMAGES.boxlog8},
];

const BeginningProgram = () => {
    const [hover, setHover] = useState(0);
    return (
        <>
            <div className="page-content bg-white">
                <PageTitle activePage="Beginners Workout Program" parentTitle="" />
                <div className='container p-5'>
                    <div className="text-black" style={{fontSize: "large"}}>
                        <ul>
                            <li>
                                <p>
                                    Are you new to the world of fitness and feeling overwhelmed by all the 
                                    options out there? Are you unsure of where to start or what workouts are 
                                    right for you? If so, it might be time to consider a beginner workout program.
                                </p>
                            </li>
                            <li>
                                <p>
                                    A beginner program is designed specifically for people who are new to exercise 
                                    or who have been inactive for a while. These programs offer a gradual introduction 
                                    to fitness, with a focus on building a strong foundation of strength and endurance.
                                </p>
                            </li>
                            <li>
                                <p>
                                    But what makes a beginner program the right choice for you? For starters, 
                                    you'll get the guidance and support of experienced trainers, who can help 
                                    you navigate the world of fitness and find the right workouts for your goals and needs. 
                                    This means you won't have to go it alone, and you'll have someone to help you stay on track and motivated.
                                </p>
                            </li>
                            <li>
                                <p>
                                    In addition, a beginner program offers a safe and supportive environment where you can 
                                    learn the basics of exercise and build confidence in your fitness journey. You'll get a 
                                    customized plan that's tailored to your needs, so you can progress at a pace that's right for you.
                                </p>
                            </li>
                            <li>
                                <p>
                                    So if you're ready to take the first step towards transforming your physique and improving your overall 
                                    health, a beginner workout program is the way to go. Don't let uncertainty or fear hold you back - invest 
                                    in yourself and your goals, and see the amazing changes that are possible with the right program and 
                                    support. Take the first step towards your fitness journey today!
                                </p>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center">
                            <Link to="https://sixpackperformance.programs.app/" target="_blank" className="btn btn-skew btn-lg btn-primary shadow-primary">
                                <span>Click here To pay and get started  your journey today!</span>
                            </Link>
                        </div>
                    </div>
                </div>
                
                {/* <section className="content-inner overflow-hidden" style={{backgroundImage: "url("+ IMAGES.BgImage1 +")"}}>
                    <div className="container">
                        <div className="row">
                            {wrapperBlog.map((item, index)=>(
                                <div className="col-xl-3 col-md-6 m-b30" key={index}>
                                    <div class={`icon-bx-wraper style-1 box-hover ${hover === index ? 'active' : ''}`}
                                        onMouseEnter={()=>setHover(index)}
                                    >
                                        <div className="icon-bx m-b30"> 
                                            <span className="icon-cell">
                                                <img src={item.image} alt="" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <h5 className="dz-title m-b10"><Link to={"#"}>{item.title}</Link></h5>
                                            <p className="m-b25">Aliquam sit amet volutpat sem, eget aliquet odio. Integer lobortis sed.</p>
                                            <Link to={"/services-details"} className="btn btn-primary shadow-primary btn-skew"><span>Read More</span></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>    
                    </div>
                </section> */}
                {/* <section className="content-inner portfolio-wrapper">
			        <div className="portfolio-wrapper-inner">
				        <div className="container-fluid  p-0">
                            <WorkoutSlider />                
                        </div>  
                    </div>
                    <svg className="shape-up" width="635" height="107" viewBox="0 0 635 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M577 0L-16 107L635 45L577 0Z" fill="var(--primary-dark)"/>
                    </svg>
                    <svg className="shape-down" width="673" height="109" viewBox="0 0 673 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M682 0L0 56L682 109V0Z" fill="var(--primary)"/>
                    </svg>      
                </section> */}
                {/* <section className="content-inner-1 testimonial-wrapper1" data-text="FEEDBACK" style={{backgroundImage: "url("+ IMAGES.BgImage2 +")", backgroundPosition: "center"}}>
                    <div className="container">
                        <div className="section-head text-center">
                            <h5 className="sub-title">TESTIMONIAL</h5>
                            <h2 className="title">What Client Say’s</h2>
                        </div>
                        <ClientSlider />

                    </div>
                    <div className="avatar1"><img src={IMAGES.avatarlarge1} alt=""/></div>
                    <div className="avatar2"><img src={IMAGES.avatarlarge2} alt=""/></div>
                    <div className="avatar3"><img src={IMAGES.avatar3} alt=""/></div>
                    <div className="avatar4"><img src={IMAGES.avatarlarge1} alt=""/></div>
                    <div className="avatar5"><img src={IMAGES.avatarlarge2} alt=""/></div>
                    <div className="avatar6"><img src={IMAGES.avatar3} alt=""/></div>
                    <img className="svg-shape rotate-360" src={SVGICON.circlebigSvg1} alt=""/>
                    <img className="svg-shape-2 rotate-360" src={SVGICON.circlebigSvg2} alt=""/>
                </section>   */}
                {/* <section className="content-inner-1 overflow-hidden" style={{backgroundImage: "url("+ IMAGES.BgImage1 +")"}}>
                    <LatestSlider />
                </section>   */}
                <section className="call-action style-1 footer-action">
			        <div className="container">
                        <NewsLetter />
                    </div>
                </section>
            </div>   
        </>
    );
};

export default BeginningProgram;