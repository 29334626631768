import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../constants/theme";
import NewsLetter from "../elements/NewsLetter";
import PageTitle from "../elements/PageTitle";

const mediaBlog = [
  { images: IMAGES.product_tee, title: "T-shirt", subTitle: "Custom one side printed(100% Polyster)"},
  { images: IMAGES.product_mug, title: "Mug", subTitle: "Both side printed Glass Mug"},
  { images: IMAGES.product_cap, title: "Cap", subTitle: "Laser printed"},
  { images: IMAGES.product_pen, title: "Pen", subTitle: "Design Wrap Ballpoint Pen"},
  { images: IMAGES.product_notebook, title: "Notebook", subTitle: "Blank pages (8.6x5.6 inches)"},
  { images: IMAGES.product_bottle, title: "Water Bottle", subTitle: "Stainless Steel Wide-Mouth Insulated Bottle(22 oz)"},
];

const Team = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="Shop all products" />
        <section className="call-action style-1 footer-action">
          <div className="container" style={{ textAlign: "center" }} > <br />
            <h4>For price and other enquiry or <br/> To order any product send me email at &nbsp;
                <a href="mailto:brandon.felix@sixpackperformance.com" className="text-primary" style={{ textDecoration: "underline" }}>
                    brandon.felix@sixpackperformance.com
                </a>
            </h4>
          </div>
        </section> <br/> <br/>
        <section className="content-inner">
          <div className="container">
            <div className="row ">
              {mediaBlog.map((item, index) => (
                <div className="col-lg-4 col-sm-6 m-b30" key={index}>
                  <div className="dz-team style-1">
                    <div className="dz-media">
                      <Link to={"#"}>
                        <img src={item.images} alt="" />
                      </Link>
                      {/* <ul className="team-social">
                        <li>
                          <Link
                            target="_blank"
                            to="https://www.facebook.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_blank"
                            to="https://twitter.com/?lang=en"
                            rel="noreferrer"
                          >
                            <i className="fab fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            target="_blank"
                            to="https://www.instagram.com/?hl=en"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </Link>
                        </li>
                      </ul> */}
                    </div>
                    <div className="dz-content ">
                      <h4 className="dz-name">{item.title}</h4>
                      <span className="dz-position" style={{ fontSize: "10px" }}>{item.subTitle}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Team;
