import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import {Link} from 'react-router-dom';
import ClientSlider from '../components/ClientSlider';
import { IMAGES, SVGICON } from '../constants/theme';
import LatestSlider from '../elements/LatestSlider';
import NewsLetter from '../elements/NewsLetter';
import PageTitle from '../elements/PageTitle';
import WorkoutSlider from '../elements/WorkoutSlider';

const videoDetails = [
    {src:"https://zcayghycaezkwvmvyhmw.supabase.co/storage/v1/object/public/data/videos/speed_training_1.mp4"},
    {src:"https://zcayghycaezkwvmvyhmw.supabase.co/storage/v1/object/public/data/videos/speed_training_2.mp4"},
    {src:"https://zcayghycaezkwvmvyhmw.supabase.co/storage/v1/object/public/data/videos/speed_training_3.mp4"},
    {src:"https://zcayghycaezkwvmvyhmw.supabase.co/storage/v1/object/public/data/videos/speed_training_4.mp4"},
    {src:"https://zcayghycaezkwvmvyhmw.supabase.co/storage/v1/object/public/data/videos/speed_training_5.mp4"},
    {src:"https://zcayghycaezkwvmvyhmw.supabase.co/storage/v1/object/public/data/videos/speed_training_6.mp4"},
    {src:"https://zcayghycaezkwvmvyhmw.supabase.co/storage/v1/object/public/data/videos/speed_training_7.mp4"},
    {src:"https://zcayghycaezkwvmvyhmw.supabase.co/storage/v1/object/public/data/videos/speed_training_8.mp4"},
];

const SpeedTrainningProgram = () => {
    const [hover, setHover] = useState(0);

    // const getVideoUrls = async () => {

    //     const { data, error } = await supabase.storage
    //         .from("data")
    //         .list("videos", {
    //             limit: 100,
    //             offset: 0,
    //             sortBy: { column: 'name', order: 'asc' }
    //         });

    //     if (data) {
    //         console.log(data);
    //     }
    // };

    // useEffect(() => {
    //     getVideoUrls();
    // }, []);

    return (
        <>
            <div className="page-content bg-white">
                <PageTitle activePage="Speed Training Program" parentTitle="" />
                {/* <div className='container p-5'>
                    <div className="text-black" style={{fontSize: "large"}}>
                        <ul>
                            <li>
                                <p>
                                    Are you tired of the same old workout routine that just isn't giving you the results you want? 
                                    Do you feel like no matter how hard you try, you just can't seem to get the toned, sculpted 
                                    physique you desire? If so, it might be time to consider an advanced workout program.
                                </p>
                            </li>
                        </ul>

                        <div className="d-flex align-items-center">
                            <Link to="https://sixpackperformance.programs.app/" target="_blank" className="btn btn-skew btn-lg btn-primary shadow-primary">
                                <span>Click here To pay and get started  your journey today!</span>
                            </Link>
                        </div>
                    </div>
                </div> */}
                
                {/* <section className="content-inner overflow-hidden" style={{backgroundImage: "url("+ IMAGES.BgImage1 +")"}}>
                    <div className="container">
                        <div className="row">
                            {wrapperBlog.map((item, index)=>(
                                <div className="col-xl-3 col-md-6 m-b30" key={index}>
                                    <div class={`icon-bx-wraper style-1 box-hover ${hover === index ? 'active' : ''}`}
                                        onMouseEnter={()=>setHover(index)}
                                    >
                                        <div className="icon-bx m-b30"> 
                                            <span className="icon-cell">
                                                <img src={item.image} alt="" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <h5 className="dz-title m-b10"><Link to={"#"}>{item.title}</Link></h5>
                                            <p className="m-b25">Aliquam sit amet volutpat sem, eget aliquet odio. Integer lobortis sed.</p>
                                            <Link to={"/services-details"} className="btn btn-primary shadow-primary btn-skew"><span>Read More</span></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>    
                    </div>
                </section> */}
                {/* <section className="content-inner portfolio-wrapper">
			        <div className="portfolio-wrapper-inner">
				        <div className="container-fluid  p-0">
                            <WorkoutSlider />                
                        </div>  
                    </div>
                    <svg className="shape-up" width="635" height="107" viewBox="0 0 635 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M577 0L-16 107L635 45L577 0Z" fill="var(--primary-dark)"/>
                    </svg>
                    <svg className="shape-down" width="673" height="109" viewBox="0 0 673 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M682 0L0 56L682 109V0Z" fill="var(--primary)"/>
                    </svg>      
                </section> */}
                {/* <section className="content-inner-1 testimonial-wrapper1" data-text="FEEDBACK" style={{backgroundImage: "url("+ IMAGES.BgImage2 +")", backgroundPosition: "center"}}>
                    <div className="container">
                        <div className="section-head text-center">
                            <h5 className="sub-title">TESTIMONIAL</h5>
                            <h2 className="title">What Client Say’s</h2>
                        </div>
                        <ClientSlider />

                    </div>
                    <div className="avatar1"><img src={IMAGES.avatarlarge1} alt=""/></div>
                    <div className="avatar2"><img src={IMAGES.avatarlarge2} alt=""/></div>
                    <div className="avatar3"><img src={IMAGES.avatar3} alt=""/></div>
                    <div className="avatar4"><img src={IMAGES.avatarlarge1} alt=""/></div>
                    <div className="avatar5"><img src={IMAGES.avatarlarge2} alt=""/></div>
                    <div className="avatar6"><img src={IMAGES.avatar3} alt=""/></div>
                    <img className="svg-shape rotate-360" src={SVGICON.circlebigSvg1} alt=""/>
                    <img className="svg-shape-2 rotate-360" src={SVGICON.circlebigSvg2} alt=""/>
                </section>   */}
                {/* <section className="content-inner-1 overflow-hidden" style={{backgroundImage: "url("+ IMAGES.BgImage1 +")"}}>
                    <LatestSlider />
                </section>   */}

                
                <div className="container mt-4 mb-4">
                    <Container>
                        <Row>
                            {videoDetails.map((item, index)=>(
                                <Col>
                                    <ReactPlayer
                                        url={item.src}
                                        controls
                                        width={300}
                                        height={500}
                                        style={{ boxShadow: "0px 0px 20px 0px", padding: "12px", margin: "12px"}}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
                {/* <iframe src="" controls></iframe> */}
                <section className="call-action style-1 footer-action">
			        <div className="container">
                        <NewsLetter />
                    </div>
                </section>
            </div>   
        </>
    );
};

export default SpeedTrainningProgram;