import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import NewsLetter from "../elements/NewsLetter";
import PageTitle from "../elements/PageTitle";
import { Link } from "react-router-dom";

const ContactMe = () => {
  // const form = useRef();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [messageError, setMessageError] = useState("");

  const emailAPI = () => {
    // e.preventDefault();
    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')

    var templateParams = {
      from_name: lastName + ", " + firstName,
      from_email: email,
      phoneNumber: phone,
      message: message,
      fromEmailLink: "mailto:" + email,
      phoneNumberLink: "tel:" + phone
    };

    emailjs
      .send(
        "service_f9r2rt3",
        "template_u158gzb",
        templateParams,
        "CHowSz5NmZKFT8LQ0"
      )
      .then(
        (result) => {
          console.log(result.text);
          swal("Good job!", "Form successfuly submmited", "success");
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setMessage("");
        },
        (error) => {
          console.log(error.text);
        }
      );
    // e.target.reset();
  };

  const validateForm = () => {
      let isValid = true;
      if (!firstName) {
          setFirstNameError("Please enter your First Name");
          isValid = false;
      }
      if (!lastName) {
          setLastNameError("Please enter your Last Name");
          isValid = false;
      }
      if (!phone) {
          setPhoneError("Please enter your phone number");
          isValid = false;
      } else if (!/^\+1\d{10}$/.test(phone)) {
          setPhoneError(
              "Please enter a valid USA phone number (e.g., +11234567890)"
          );
          isValid = false;
      }
      if (!email) {
          setEmailError("Please enter your email address");
          isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(email)) {
          setEmailError("Please enter a valid email address");
          isValid = false;
      }
      if (!message) {
          setMessageError("Please enter your Message");
          isValid = false;
      }
      return isValid;
  };

  const sendEmail = async () => {
    if(validateForm()) {
      emailAPI();
    }
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageTitle activePage="Contact Me" parentTitle="Home" />
        <section className="content-inner-2 z-index-none">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-xl-5 m-sm-b30 m-xl-b0">
                <div className="contact-box">
                  <h3 className="contact-title">Contact Information</h3>
                  <p className="contact-text">
                    Submit this form and I will get back to you within 24 hours.
                  </p>
                  <div className="widget widget_getintuch ">
                    <ul>
                      <li>
                        <i className="fa-solid fa-location-dot"></i>
                        <p>
                          Greensboro, North Carolina, United States
                        </p>
                      </li>
                      <li>
                        <i className="fa-solid fa-phone"></i>
                        <p>+1 954-558-0434</p>
                      </li>
                      <li>
                        <i className="fa-solid fa-envelope"></i>
                        <p>brandon.felix@sixpackperformance.com</p>
                      </li>
                    </ul>
                  </div>
                  <h6 className="m-b15 text-white">Find me on Socials</h6>
                  <div className="dz-social-icon style-1 dark">
                    <ul>
                      <li>
                          <Link target="_blank" to="https://www.youtube.com/" rel="noreferrer">
                              <i className="fab fa-youtube"></i>
                          </Link>
                      </li>{" "}
                      <li>
                          <Link target="_blank" to="https://www.facebook.com/" rel="noreferrer">
                              <i className="fab fa-facebook-f"></i>
                          </Link>
                      </li>{" "}
                      <li>
                          <Link target="_blank" to="https://www.instagram.com/sixpack_performance/" rel="noreferrer">
                              <i className="fab fa-instagram"></i>
                          </Link>
                      </li>{" "}
                      <li>
                          <Link target="_blank" to="https://www.whatsapp.com/" rel="noreferrer">
                              <i className="fa-brands fa-whatsapp"></i>
                          </Link>
                      </li>
                    </ul>
                    <br />
                    <ul>
                      <li>
                          <Link target="_blank" to="https://www.tiktok.com/" rel="noreferrer">
                              <i className="fab fa-tiktok"></i>
                          </Link>
                      </li>{" "}
                      <li>
                          <Link target="_blank" to="https://www.twitter.com/" rel="noreferrer">
                              <i className="fab fa-twitter"></i>
                          </Link>
                      </li>{" "}
                      <li>
                          <Link target="_blank" to="https://www.snapchat.com/" rel="noreferrer">
                              <i className="fab fa-snapchat"></i>
                          </Link>
                      </li>{" "}
                      <li>
                          <Link target="_blank" to="https://www.telegram.com/" rel="noreferrer">
                              <i className="fa-brands fa-telegram"></i>
                          </Link>
                      </li>
                    </ul>
                  </div>
                  <svg
                    width="250"
                    height="70"
                    viewBox="0 0 250 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 38L250 0L210 70L0 38Z"
                      fill="url(#paint0_linear_306_1296)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_306_1296"
                        x1="118.877"
                        y1="35.552"
                        x2="250.365"
                        y2="35.552"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="1" stopColor="var(--primary)" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="col-md-6 col-xl-7">
                <form
                  className="dz-form dzForm style-1"
                >
                  <input
                    type="hidden"
                    className="form-control"
                    name="dzToDo"
                    value="Contact"
                  />
                  <div className="dzFormMsg"></div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-group input-line">
                        <input
                          name="dzFirstName"
                          required
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => {
                              setFirstName(e.target.value);
                              setFirstNameError("");
                          }}
                        />
                        {firstNameError && <div className="error">{firstNameError}</div>}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group input-line">
                        <input
                          name="dzLastName"
                          required
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => {
                              setLastName(e.target.value);
                              setLastNameError("");
                          }}
                        />
                        {lastNameError && <div className="error">{lastNameError}</div>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-group input-line">
                        <input
                            type="email"
                            name="dzEmail"
                            value={email}
                            className="form-control"
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError("");
                            }}
                            placeholder="Your Email"
                            required
                        />
                        {emailError && <div className="error">{emailError}</div>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-group input-line">
                        <input
                            type="text"
                            name="dzPhoneNumber"
                            className="form-control"
                            value={phone === "" || phone === "+" ? "+1" : phone}
                            onChange={(e) => {
                                const numericValue = e.target.value.replace(
                                    /[^0-9+]*/g,
                                    ""
                                );
                                const truncatedValue = numericValue.slice(0, 12);
                                setPhone(truncatedValue);
                                setPhoneError("");
                            }}
                            placeholder="Your Phone number"
                            required
                        />

                        {phoneError && <div className="error">{phoneError}</div>}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="input-group input-line m-b30">
                        <textarea
                          name="dzMessage"
                          rows="5"
                          required
                          className="form-control"
                          placeholder="Message..."
                          value={message}
                          onChange={(e) => {
                              setMessage(e.target.value);
                              setMessageError("");
                          }}
                        ></textarea>

                        {messageError && <div className="error">{messageError}</div>}

                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button
                        className="btn btn-primary btn-lg btn-skew"
                        onClick={(e) => {
                          e.preventDefault();
                          sendEmail();
                        }}
                      >
                        <span>Send Message</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="container content-inner-1">
          <div className="map-iframe">
            <iframe
              title="myFrame"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28896.531392443423!2d75.81462525569334!3d25.133445080066668!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x537f208422eb8f28!2sW3ITEXPERTS%20-%20Software%20Development%20Company%20in%20kota!5e0!3m2!1sen!2sin!4v1669897446044!5m2!1sen!2sin"
              style={{ border: "0", marginBottom: "-7px", width: "100%" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div> */}
        <br></br>
        <br></br>
        <br></br>
        <section className="call-action style-1 footer-action">
          <div className="container">
            <NewsLetter />
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactMe;
