
import React from 'react';
import CountUp from 'react-countup';

const counterData = [
    {number: "6", title:"Years of Experience", styleChange:"col-12"},
    {number: "50", title:"Clients", styleChange:"col-6"},
    {number: "150", title:"Exercises Explore with Me", styleChange:"col-6"},
];

const CounterBlog = () => {
    return (
        <>
            {counterData.map((item, ind)=>(
                <div className={`col-sm-4 m-b30 ${item.styleChange}`} key={ind}>                
                    <div className="counter-box">
                        <h3 className="title counter">
                            <CountUp end={item.number} duration={3}/>    
                        </h3>
                        <p>{item.title}</p>
                    </div>
                </div>
            ))}
        </>
    );
};

export default CounterBlog;